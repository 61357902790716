import { ApolloError, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import {
  CartInput,
  MutationCartCreateArgs,
} from "@shopify/hydrogen-react/storefront-api-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

import { CART_ID_KEY } from "../../config";
import { CART_CREATE } from "../graphql/cart";
import { Cart, CartCreatePayload } from "../types/shopify";
import { setLocalStorageItem } from "../utils/storage";

interface UseCartCreate {
  createCart: (input: CartInput) => Promise<Cart | null | undefined>;
  data?: CartCreatePayload;
  error?: ApolloError;
  loading: boolean;
}

interface UseCartCreateOptions {
  useCache: boolean;
}

export interface UseCartCreateData {
  cartCreate: CartCreatePayload;
}

const useCartCreate = ({ useCache }: UseCartCreateOptions): UseCartCreate => {
  const [mutation, { data, error: apolloError, loading }] = useMutation<
    UseCartCreateData,
    MutationCartCreateArgs
  >(CART_CREATE);

  if (apolloError) {
    Sentry.captureException(apolloError);
  }

  const createCart = async (
    input: CartInput,
  ): Promise<Cart | null | undefined> => {
    try {
      const { data } = await mutation({ variables: { input } });

      if (data) {
        if (data.cartCreate.userErrors.length) {
          data.cartCreate.userErrors.forEach(cartUserError => {
            Sentry.captureException(cartUserError);
          });
          return null;
        }

        if (data.cartCreate.cart) {
          if (useCache) {
            setLocalStorageItem(CART_ID_KEY, data.cartCreate.cart.id);
          }
          return data.cartCreate.cart;
        }
      }

      return null;
    } catch (error) {
      toast.error(
        <p data-testid="toast">
          <FormattedMessage id="cart.errorCreatingCart" />
        </p>,
      );
      Sentry.captureException(error);
      return null;
    }
  };

  return {
    createCart,
    loading: typeof window === "undefined" ? false : loading,
    error: apolloError,
    data: data?.cartCreate,
  };
};

export default useCartCreate;
