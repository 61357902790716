import * as Sentry from "@sentry/nextjs";
import { AttributeInput } from "@shopify/hydrogen-react/storefront-api-types";

import { SEASON_CARD_DATA_KEY } from "../../config";
import { Cart } from "../types/shopify";
import { CartItem, CartSummary } from "../types/shopify-components";

import { getCustomAttributesByKeys } from "./common";
import {
  getExtendedCartMerchandise,
  ProductType,
  SEASON_CARD_ATTRIBUTES,
} from "./product";

export function mapCartToCartItems(cart?: Cart): CartItem[] {
  if (cart) {
    return cart.lines.edges.reduce<CartItem[]>((acc, { node }) => {
      if (node.merchandise) {
        const { id, quantity, attributes, merchandise } = node;
        const cartItem: CartItem = {
          merchandise: getExtendedCartMerchandise(merchandise, {
            title: merchandise.product.title,
            handle: merchandise.product.handle,
            productType: merchandise.product.productType,
            tags: merchandise.product.tags,
          }),
          input: {
            merchandiseId: merchandise.id,
            quantity,
            attributes: attributes.reduce<AttributeInput[]>((acc, cur) => {
              if (cur.value && cur.value !== "") {
                const { key, value } = cur;
                acc.push({ key, value });
              }
              return acc;
            }, []),
          },
          cartLineId: id,
        };
        acc.push(cartItem);
      }
      return acc;
    }, []);
  }
  return [];
}

export function getCartSummary(items: CartItem[]): CartSummary {
  const filteredItems = items.filter(
    item => item.merchandise.productType !== ProductType.COMPOSITE_CHILD,
  );
  const itemCount = filteredItems.reduce((count, { input }) => {
    return count + (input.quantity ?? 0);
  }, 0);
  const prices = filteredItems.reduce(
    (prev, { input, merchandise }) => {
      const quantity = input.quantity ?? 0;
      const price = Number(merchandise.price.amount);
      const compareAtPrice = merchandise.compareAtPrice
        ? Number(merchandise.compareAtPrice.amount)
        : price;

      const newTotalPrice = Number.isNaN(price)
        ? prev.totalPrice
        : prev.totalPrice + quantity * price;
      const newComparePriceTotal = Number.isNaN(compareAtPrice)
        ? prev.comparePriceTotal
        : prev.comparePriceTotal + quantity * compareAtPrice;
      const newComparePriceDiscount =
        newComparePriceTotal > newTotalPrice
          ? Math.abs(newComparePriceTotal - newTotalPrice)
          : 0;

      return {
        totalPrice: newTotalPrice,
        comparePriceTotal: newComparePriceTotal,
        comparePriceDiscount: newComparePriceDiscount,
      };
    },
    {
      totalPrice: 0,
      comparePriceTotal: 0,
      comparePriceDiscount: 0,
    },
  );

  return {
    itemCount,
    ...prices,
  };
}

export function storeSeasonCardInSessionStorage(item: CartItem): void {
  if (
    item.merchandise.productType === ProductType.SEASON_CARD &&
    item.input.attributes
  ) {
    const [
      firstName,
      lastName,
      dateOfBirth,
      guardianFirstName,
      guardianLastName,
      address,
      zip,
      city,
      email,
      phone,
      photoId,
      ticketId,
    ] = getCustomAttributesByKeys(item.input.attributes, [
      SEASON_CARD_ATTRIBUTES.FIRST_NAME,
      SEASON_CARD_ATTRIBUTES.LAST_NAME,
      SEASON_CARD_ATTRIBUTES.DATE_OF_BIRTH,
      SEASON_CARD_ATTRIBUTES.GUARDIAN_FIRST_NAME,
      SEASON_CARD_ATTRIBUTES.GUARDIAN_LAST_NAME,
      SEASON_CARD_ATTRIBUTES.ADDRESS,
      SEASON_CARD_ATTRIBUTES.POSTAL_CODE,
      SEASON_CARD_ATTRIBUTES.CITY,
      SEASON_CARD_ATTRIBUTES.EMAIL,
      SEASON_CARD_ATTRIBUTES.PHONE,
      SEASON_CARD_ATTRIBUTES.PHOTO_ID,
      SEASON_CARD_ATTRIBUTES.TICKET_ID,
    ]);

    try {
      sessionStorage.setItem(
        SEASON_CARD_DATA_KEY,
        JSON.stringify({
          shouldOpenModal: true,
          cartLineId: item.cartLineId,
          merchandiseId: item.merchandise.id,
          photoId,
          fields: {
            firstName,
            lastName,
            dateOfBirth,
            guardianFirstName,
            guardianLastName,
            address,
            zip,
            city,
            email,
            phone,
            ticketId,
          },
        }),
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export const hasShippingMethodConflict = (items: CartItem[]): boolean => {
  let hasMailOnlyProduct = false;
  let hasSeasonCard = false;
  for (const item of items) {
    const { productType, weight } = item.merchandise;
    const shippableOnlyByMail =
      weight !== undefined && weight !== null && weight >= 1;

    hasMailOnlyProduct = hasMailOnlyProduct || shippableOnlyByMail;
    hasSeasonCard = hasSeasonCard || productType === ProductType.SEASON_CARD;
    if (hasMailOnlyProduct && hasSeasonCard) {
      return true;
    }
  }
  return false;
};
