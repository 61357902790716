/// <reference types="../backend/public/api/generated/types/public" />

import { env } from "./environment";

const hostname = env.SARKANNIEMI_HOST;
const protocol = hostname.includes("localhost") ? "http" : "https";

export const SITE_URL = `${protocol}://${hostname}`;
export const SITEMAP_FILENAME = "sitemap.xml";
export const ROBOTS_FILENAME = "robots.txt";
export const PUBLIC_FOLDER = "public";

// Shopify
export const SHOP_TIMEZONE = "Europe/Helsinki";
export const COLLECTION_MAX_COUNT = 250;
export const COLLECTION_PRODUCT_MAX_COUNT = 250;
export const COLLECTION_PRODUCT_VARIANT_MAX_COUNT = 100;
export const PRODUCT_MAX_COUNT = 250;
export const PRODUCT_VARIANT_MAX_COUNT = 100;
export const SUB_PRODUCT_MAX_COUNT = 250;
export const SUB_PRODUCT_VARIANT_MAX_COUNT = 100;
export const SITEMAP_PRODUCT_MAX_COUNT = 250;

// TODO: Move checkout line item max count here too

export const CART_ID_KEY = "cartId";
export const CHECKOUT_ID_KEY = "checkoutId";
export const CLIENT_KEY: Components.Schemas.CustomAttributeKeyPurchase =
  "sarkanniemiClient";
export const CLIENT_MOBILE: Components.Schemas.SarkanniemiClient = "mobile";
export const EXTERNAL_USER_ID_KEY: Components.Schemas.CustomAttributeKeyPurchase =
  "external_user_id";
export const SEASON_CARD_DATA_KEY = "season_card_data";
export const ORGANIZATION_ID_TOKEN_KEY = "orgIdToken";
export const ORGANIZATION_REFRESH_TOKEN_KEY = "orgRefreshToken";

export const AMPLIFY_CONFIG = {
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE", "APPLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 12,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_project_region: env.COGNITO_REGION,
  aws_cognito_region: env.COGNITO_REGION,
  aws_user_pools_id: env.CONSUMER_USER_POOL_ID,
  aws_user_pools_web_client_id: env.CONSUMER_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: env.CONSUMER_COGNITO_DOMAIN,
    responseType: "code",
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    // Note: redirectSignIn and redirectSignOut are set dynamically in _app.tsx
  },
};
