import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";

import { EXTERNAL_USER_ID_KEY, CLIENT_KEY, CLIENT_MOBILE } from "../../config";
import { getCartSummary, mapCartToCartItems } from "../ecommerce/cart";
import { getProductSKUFromTags } from "../ecommerce/product";
import useCart from "../hooks/useCart";
import useCartAttributes from "../hooks/useCartAttributes";
import useCartCreate from "../hooks/useCartCreate";
import useCartLinesAdd from "../hooks/useCartLinesAdd";
import useCartLinesRemove from "../hooks/useCartLinesRemove";
import {
  triggerAddToCartEvent,
  triggerRemoveFromCartEvent,
} from "../seo/data-layer";
import { Attribute, Cart } from "../types/shopify";
import {
  CartItem,
  CartSummaries,
  ExtendedCartMerchandise,
} from "../types/shopify-components";

import { AuthenticationContext } from "./AuthenticationContext";
import { CollectionsContext } from "./CollectionsContext";
import { MiniCartVisibilityContext } from "./MiniCartVisibilityContext";
import { MobileContext } from "./MobileContext";

interface UpdateOptions {
  openMiniCart: boolean;
  newItems?: CartItem[];
}

interface IncreaseQuantityOptions {
  shouldUpdate?: boolean;
  merchandises: ExtendedCartMerchandise[];
}

interface DecreaseQuantityOptions {
  allowZero?: boolean;
  shouldUpdate?: boolean;
  merchandises: ExtendedCartMerchandise[];
}

export interface CartContextProps {
  attributes: Attribute[];
  localItems: CartItem[];
  cartItems: CartItem[];
  checkoutUrl?: string;
  summaries: CartSummaries;
  increaseQuantity: (options: IncreaseQuantityOptions) => void;
  decreaseQuantity: (options: DecreaseQuantityOptions) => void;
  resetQuantities: (merchandiseIds: string[]) => void;
  remove: (items: CartItem[]) => Promise<void>;
  update: (options: UpdateOptions) => Promise<boolean>;
  hasMerchandise: (
    merchandiseId: string,
    inExistingCheckout: boolean,
  ) => boolean;
  isLoading: boolean;
  isCreating: boolean;
  isAddingLines: boolean;
  isRemovingLines: boolean;
  isTouched: boolean;
}

export const cartContextDefault: CartContextProps = {
  attributes: [],
  localItems: [],
  cartItems: [],
  checkoutUrl: undefined,
  summaries: {
    cart: {
      comparePriceDiscount: 0,
      comparePriceTotal: 0,
      itemCount: 0,
      totalPrice: 0,
    },
    local: {
      comparePriceDiscount: 0,
      comparePriceTotal: 0,
      itemCount: 0,
      totalPrice: 0,
    },
  },
  increaseQuantity: () => undefined,
  decreaseQuantity: () => undefined,
  resetQuantities: () => undefined,
  remove: async () => undefined,
  update: async () => false,
  hasMerchandise: () => false,
  isLoading: true,
  isCreating: false,
  isAddingLines: false,
  isRemovingLines: false,
  isTouched: false,
};

export const CartContext =
  React.createContext<CartContextProps>(cartContextDefault);

interface CartProviderProps {
  children?: React.ReactNode;
  cartId?: string;
  contextProps?: Partial<CartContextProps>;
}

const CartProvider: React.FC<CartProviderProps> = ({
  children,
  cartId,
  contextProps,
}) => {
  const { user } = useContext(AuthenticationContext);
  const { isMobile } = useContext(MobileContext);
  const [isTouched, setIsTouched] = useState(false);
  const [localItemsInitialized, setLocalItemsInitialized] = useState(false);
  const [localItems, setLocalItems] = useState<CartItem[]>([]);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [summaries, setSummaries] = useState<CartSummaries>({
    cart: {
      comparePriceDiscount: 0,
      comparePriceTotal: 0,
      itemCount: 0,
      totalPrice: 0,
    },
    local: {
      comparePriceDiscount: 0,
      comparePriceTotal: 0,
      itemCount: 0,
      totalPrice: 0,
    },
  });

  const { cart, refetch: refetchCart, loading: isLoading } = useCart(cartId);
  const { createCart, loading: isCreating } = useCartCreate({ useCache: true });
  const { addCartLines, loading: isAddingLines } = useCartLinesAdd();
  const { removeCartLines, loading: isRemovingLines } = useCartLinesRemove();
  const { updateAttributes } = useCartAttributes();
  const { showMiniCart } = useContext(MiniCartVisibilityContext);
  const { products, loading: isCollectionsLoading } =
    useContext(CollectionsContext);

  const checkTouched = useCallback(
    (newItems: CartItem[]): boolean => {
      const existing = orderBy(cartItems, item => item.input.merchandiseId);
      const updated = orderBy(newItems, item => item.input.merchandiseId);
      return !isEqual(existing, updated);
    },
    [cartItems],
  );

  const handleLocalItemsChange = useCallback(
    (newItems: CartItem[]) => {
      setLocalItems(newItems);
      const touched = checkTouched(newItems);
      setIsTouched(touched);
    },
    [checkTouched],
  );

  const handleSubProducts = useCallback(
    (cart: Cart): Cart => {
      return {
        ...cart,
        lines: {
          edges: cart.lines.edges.map(edge => {
            const { merchandise } = edge.node;
            const subData =
              merchandise && getProductSKUFromTags(merchandise.product.tags);
            if (subData?.type === "sub") {
              const containerProduct = products.find(product => {
                const productData = getProductSKUFromTags(product.tags);
                if (productData) {
                  return (
                    productData.type === "super" &&
                    productData.sku === subData.sku
                  );
                }
              });
              if (containerProduct) {
                return {
                  ...edge,
                  node: {
                    ...edge.node,
                    merchandise: edge.node.merchandise
                      ? {
                          ...edge.node.merchandise,
                          product: {
                            ...edge.node.merchandise.product,
                            handle: containerProduct.handle,
                          },
                        }
                      : null,
                  },
                };
              }
            }
            return edge;
          }),
        },
      };
    },
    [products],
  );

  const handleCartItemsChange = useCallback(
    (cart: Cart): CartItem[] => {
      return mapCartToCartItems(handleSubProducts(cart));
    },
    [handleSubProducts],
  );

  const create = useCallback(
    async (items: CartItem[]) => {
      if (!cart) {
        const createdCart = await createCart({
          lines: items.map(item => item.input),
        });
        if (createdCart) {
          const items = handleCartItemsChange(createdCart);
          setCartItems(items);
          setIsTouched(false);
          showMiniCart();
          return true;
        }
      }
      return false;
    },
    [createCart, cart, handleCartItemsChange, showMiniCart],
  );

  const update =
    contextProps?.update ??
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCallback(
      async ({ openMiniCart, newItems }: UpdateOptions) => {
        if (!cart) {
          return await create(newItems ?? localItems);
        } else {
          if (isTouched || newItems) {
            const itemsInCart = newItems ?? localItems;
            // Storefront Cart API doesn't have replaceLines functionality, so we need to do remove + add instead
            const lineIdsToRemove = cartItems.reduce<string[]>(
              (lineIds, cartItem) => {
                if (cartItem.cartLineId !== undefined) {
                  lineIds.push(cartItem.cartLineId);
                }
                return lineIds;
              },
              [],
            );
            await removeCartLines({
              cartId: cart.id,
              lineIds: lineIdsToRemove,
            });
            const updatedCart = await addCartLines({
              cartId: cart.id,
              lines: itemsInCart.map(item => item.input),
            });

            if (!updatedCart) {
              return false;
            }

            const updatedItems = handleCartItemsChange(updatedCart);
            setCartItems(updatedItems);
            setLocalItems(updatedItems);
            setIsTouched(false);

            if (openMiniCart) {
              showMiniCart();
            }

            return true;
          }
          return false;
        }
      },
      [
        create,
        cart,
        cartItems,
        handleCartItemsChange,
        localItems,
        isTouched,
        addCartLines,
        removeCartLines,
        showMiniCart,
      ],
    );

  const updateDebounced = useDebouncedCallback(update, 500);

  const remove = useCallback(
    async (removedItems: CartItem[]) => {
      const newItems = localItems.filter(
        item =>
          !removedItems.some(
            ({ cartLineId }) => cartLineId === item.cartLineId,
          ),
      );
      const success = await update({ openMiniCart: false, newItems });

      if (success) {
        for (const { merchandise, input } of removedItems) {
          triggerRemoveFromCartEvent(merchandise, input.quantity ?? undefined);
        }
      }
    },
    [localItems, update],
  );

  const increaseQuantity = useCallback(
    ({ merchandises, shouldUpdate }: IncreaseQuantityOptions) => {
      const updatedMerchandiseIds: string[] = [];
      const createdMerchandises: ExtendedCartMerchandise[] = [];

      for (const merchandise of merchandises) {
        if (
          localItems.some(
            localItem => localItem.input.merchandiseId === merchandise.id,
          )
        ) {
          updatedMerchandiseIds.push(merchandise.id);
        } else {
          createdMerchandises.push(merchandise);
        }
      }

      const newItems: CartItem[] = [
        ...localItems.map(item => {
          if (updatedMerchandiseIds.includes(item.input.merchandiseId)) {
            return {
              ...item,
              input: {
                ...item.input,
                quantity: (item.input.quantity ?? 0) + 1,
              },
            };
          }
          return item;
        }),
        ...createdMerchandises.map(merchandise => ({
          merchandise,
          input: {
            merchandiseId: merchandise.id,
            quantity: 1,
            attributes: [],
          },
        })),
      ];

      for (const merchandise of merchandises) {
        triggerAddToCartEvent(merchandise);
      }

      handleLocalItemsChange(newItems);

      if (shouldUpdate) {
        updateDebounced({ openMiniCart: false });
      }
    },
    [localItems, handleLocalItemsChange, updateDebounced],
  );

  const decreaseQuantity = useCallback(
    ({ merchandises, allowZero, shouldUpdate }: DecreaseQuantityOptions) => {
      const removedMerchandises: ExtendedCartMerchandise[] = [];
      webkitURL;

      const updatedItems = localItems.reduce<CartItem[]>((items, item) => {
        const existingMerchandise = merchandises.find(
          merchandise => merchandise.id === item.input.merchandiseId,
        );

        if (
          existingMerchandise &&
          item.input.quantity &&
          item.input.quantity > 0
        ) {
          const newQuantity = item.input.quantity - 1;

          if (newQuantity > 0) {
            items.push({
              ...item,
              input: {
                ...item.input,
                quantity: newQuantity,
              },
            });
            removedMerchandises.push(existingMerchandise);
          } else if (localItems.length && allowZero) {
            removedMerchandises.push(existingMerchandise);
          } else {
            items.push(item);
            toast.info(
              <p className="ma0" data-testid="toast">
                <FormattedMessage id="cart.useRemoveButtonToRemoveProductFromCart" />
              </p>,
              {
                toastId: "toast-use-remove-button-to-remove-product-from-cart",
              },
            );
          }
        } else {
          items.push(item);
        }
        return items;
      }, []);

      handleLocalItemsChange(updatedItems);

      for (const merchandise of removedMerchandises) {
        triggerRemoveFromCartEvent(merchandise);
      }

      if (shouldUpdate) {
        updateDebounced({ openMiniCart: false });
      }
    },
    [localItems, handleLocalItemsChange, updateDebounced],
  );

  const resetQuantities = useCallback(
    (merchandiseIds: string[]) => {
      if (cartItems.length === 0 && localItems.length === 0) {
        return;
      }

      const newItems = cartItems.reduce<CartItem[]>((items, item) => {
        const merchandiseId = merchandiseIds.find(
          id => id === item.input.merchandiseId,
        );

        if (merchandiseId) {
          const cartQuantity = cartItems.find(
            ({ merchandise }) => merchandise.id === item.input.merchandiseId,
          )?.input.quantity;

          // no quantity, drop item
          if (!cartQuantity) {
            return items;
          }

          // is in given merchandise IDs, use cart quantity
          return [
            ...items,
            {
              ...item,
              input: {
                ...item.input,
                quantity: cartQuantity,
              },
            },
          ];
        } else {
          const localQuantity = localItems.find(
            ({ merchandise }) => merchandise.id === item.input.merchandiseId,
          )?.input.quantity;

          // no quantity, drop item
          if (!localQuantity) {
            return items;
          }

          // not in given merchandise IDs, use local quantity
          return [
            ...items,
            {
              ...item,
              input: {
                ...item.input,
                quantity: localQuantity,
              },
            },
          ];
        }
      }, []);
      handleLocalItemsChange(newItems);
    },
    [cartItems, localItems, handleLocalItemsChange],
  );

  const hasMerchandise = useCallback(
    (merchandiseId: string, inExistingCart = false) => {
      if (inExistingCart) {
        return cartItems.some(
          ({ merchandise }) => merchandise.id === merchandiseId,
        );
      }
      return localItems.some(
        item => item.input.merchandiseId === merchandiseId,
      );
    },
    [cartItems, localItems],
  );

  // Initialize items once if cart exists
  useEffect(() => {
    if (cart && !localItemsInitialized && !isCollectionsLoading) {
      const items = handleCartItemsChange(cart);
      setLocalItemsInitialized(true);
      setCartItems(items);
      setLocalItems(items);
    }
  }, [
    cart,
    handleCartItemsChange,
    isCollectionsLoading,
    localItemsInitialized,
  ]);

  // Update summaries if local or cart items change
  useEffect(() => {
    setSummaries({
      cart: getCartSummary(cartItems),
      local: getCartSummary(localItems),
    });
  }, [cartItems, localItems]);

  // Update custom attributes if changed
  useEffect(() => {
    if (cart?.id && cart?.attributes) {
      const externalUserId = cart.attributes.find(
        ({ key }) => key === EXTERNAL_USER_ID_KEY,
      )?.value;
      const client = cart.attributes.find(
        ({ key }) => key === CLIENT_KEY,
      )?.value;

      const newAttributes = [];
      const updateUser = externalUserId !== user?.id;
      const updateClient =
        (isMobile && client !== CLIENT_MOBILE) ||
        (!isMobile && client === CLIENT_MOBILE);
      if (updateUser || updateClient) {
        if (user) {
          newAttributes.push({
            key: EXTERNAL_USER_ID_KEY,
            value: user.id,
          });
        }
        if (isMobile) {
          newAttributes.push({
            key: CLIENT_KEY,
            value: CLIENT_MOBILE,
          });
        }
        updateAttributes(cart.id, newAttributes).then(() => {
          refetchCart();
        });
      }
    }
  }, [
    cart?.id,
    cart?.attributes,
    user,
    isMobile,
    refetchCart,
    updateAttributes,
  ]);

  return (
    <CartContext.Provider
      value={{
        attributes: cart?.attributes ?? [],
        localItems,
        cartItems,
        checkoutUrl: cart?.checkoutUrl,
        summaries,
        decreaseQuantity,
        increaseQuantity,
        resetQuantities,
        remove,
        update,
        hasMerchandise,
        isLoading,
        isCreating,
        isAddingLines,
        isRemovingLines,
        isTouched,
        ...contextProps,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
