import { ApolloError, ApolloQueryResult, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

import { CART_ID_KEY } from "../../config";
import { CART_GET } from "../graphql/cart";
import { Cart } from "../types/shopify";
import { getLocalStorageItem, removeLocalStorageItem } from "../utils/storage";

export interface UseCartData {
  cart: Cart | null;
}

export interface UseCartVariables {
  cartId?: Cart["id"];
}

interface UseCart {
  cart?: Cart;
  error?: ApolloError;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<UseCartData>>;
}

const useCart = (id?: Cart["id"]): UseCart => {
  const cartId = id ?? getLocalStorageItem(CART_ID_KEY);

  const { data, error, loading, refetch } = useQuery<
    UseCartData,
    UseCartVariables
  >(CART_GET, {
    variables: {
      cartId,
    },
    skip: cartId === undefined,
    fetchPolicy: "no-cache",
  });

  const fetchedCart = data?.cart;

  useEffect(() => {
    if (fetchedCart === null) {
      removeLocalStorageItem(CART_ID_KEY);
    }
  }, [cartId, fetchedCart]);

  if (error) {
    Sentry.captureException(error);
  }

  const cart = fetchedCart ?? undefined;

  return {
    cart,
    loading: typeof window === "undefined" ? true : loading,
    error,
    refetch,
  };
};

export default useCart;
