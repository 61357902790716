import { gql } from "@apollo/client";

const CART_FRAGMENT = gql`
  fragment CART_FRAGMENT on Cart {
    id
    buyerIdentity {
      email
    }
    checkoutUrl
    updatedAt
    lines(first: 250) {
      edges {
        node {
          id
          quantity
          merchandise {
            ... on ProductVariant {
              availableForSale
              quantityAvailable
              id
              title
              sku
              product {
                id
                handle
                title
                tags
                productType
              }
              price {
                amount
              }
              compareAtPrice {
                amount
              }
              image {
                id
                url
              }
              selectedOptions {
                name
                value
              }
              weight
            }
          }
          attributes {
            key
            value
          }
        }
      }
    }
    attributes {
      key
      value
    }
    cost {
      totalAmount {
        amount
      }
      subtotalAmount {
        amount
      }
    }
  }
`;

export const CART_GET = gql`
  query cart($cartId: ID!) {
    cart(id: $cartId) {
      ...CART_FRAGMENT
    }
  }
  ${CART_FRAGMENT}
`;

export const CART_CREATE = gql`
  mutation cartCreate($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        ...CART_FRAGMENT
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CART_FRAGMENT}
`;

export const CART_LINES_ADD = gql`
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CART_FRAGMENT
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CART_FRAGMENT}
`;

export const CART_LINES_REMOVE = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CART_FRAGMENT
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CART_FRAGMENT}
`;

export const CART_LINES_UPDATE = gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CART_FRAGMENT
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CART_FRAGMENT}
`;

export const CART_ATTRIBUTES_UPDATE = gql`
  mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
    cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
      cart {
        ...CART_FRAGMENT
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CART_FRAGMENT}
`;
