import { ApolloProvider } from "@apollo/client";
import { Amplify } from "aws-amplify";
import { AppInitialProps, AppContext } from "next/app";
import React, { useEffect } from "react";
import "isomorphic-unfetch";
import GTM from "react-gtm-module";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import "../../node_modules/react-toastify/dist/ReactToastify.css";

import { AMPLIFY_CONFIG, CHECKOUT_ID_KEY } from "../../config";
import { env } from "../../environment";
import { injectedScripts } from "../../injected-scripts";
import { useApollo } from "../apollo/apollo-client";
import ErrorPage from "../components/ErrorPage";
import Page from "../components/Page";
import { Scripts } from "../components/Scripts";
import AuthenticationProvider from "../contexts/AuthenticationContext";
import CalendarProvider from "../contexts/CalendarContext";
import MenuProvider from "../contexts/MenuContext";
import MobileProvider from "../contexts/MobileContext";
import OrganizationProvider from "../contexts/OrganizationContext";
import WithLocale from "../hocs/withLocale";
import useScrollRestoration from "../hooks/useScrollRestoration";
import { isLocale, getLocale, defaultLocale } from "../lib/i18n";
import "../../public/fonts/fonts.css";
import "../../public/fonts/fonts-bebas-neue.css";
import "../styles/main.scss";
import "../../node_modules/photoswipe/dist/photoswipe.css";
import "../../node_modules/photoswipe/dist/default-skin/default-skin.css";
import "../../node_modules/swiper/css/swiper.css";
import { ApolloAppContext } from "../types/apollo";
import { getLocalStorageItem } from "../utils/storage";

Modal.setAppElement("body");

type MyAppInitialProps = AppInitialProps & {
  locale: string;
  err?: Error;
  pathname: string;
};

type MyAppProps = AppContext & MyAppInitialProps;

function MyApp({
  Component,
  err,
  pageProps,
  router,
  locale,
  pathname,
}: MyAppProps): React.ReactNode {
  const apolloClient = useApollo(pageProps, locale);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const redirectSignIn = `${location.origin}/${locale}`;
      const redirectSignOut = `${location.origin}/${locale}`;

      Amplify.configure({
        ...AMPLIFY_CONFIG,
        oauth: {
          ...AMPLIFY_CONFIG.oauth,
          redirectSignIn,
          redirectSignOut,
        },
      });
    }
  }, [locale]);

  // TODO: Remove if Pandectes integration works
  useEffect(() => {
    if (
      env.ENVIRONMENT === "production" &&
      env.GOOGLE_TAG_MANAGER_ID !== undefined
    ) {
      GTM.initialize({
        gtmId: env.GOOGLE_TAG_MANAGER_ID,
      });
    }
  }, []);

  useEffect(() => {
    toast.configure();
  }, []);

  useScrollRestoration(router);

  if (!isLocale(locale) || pathname === "/_error") {
    const usedLocale = isLocale(locale) ? locale : defaultLocale;
    return (
      <WithLocale locale={usedLocale}>
        <MobileProvider>
          <MenuProvider locale={usedLocale}>
            <ErrorPage />
          </MenuProvider>
        </MobileProvider>
      </WithLocale>
    );
  }

  const checkoutId = getLocalStorageItem(CHECKOUT_ID_KEY);

  const component = <Component {...pageProps} err={err} />;

  if (pathname.startsWith("/[locale]/organizations/[orgId]")) {
    const { orgId } = router.query;

    if (typeof orgId !== "string") {
      throw new Error("[orgId] not found from path");
    }

    return (
      <WithLocale locale={locale}>
        <OrganizationProvider organizationId={orgId}>
          <ToastContainer
            style={{ zIndex: 9999999 }}
            position="bottom-right"
            autoClose={5000}
            newestOnTop={true}
            limit={1}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={true}
            hideProgressBar={true}
          />
          {component}
        </OrganizationProvider>
      </WithLocale>
    );
  }

  switch (pathname) {
    case "/[locale]/kausikortti/[id]": {
      return (
        <WithLocale locale={locale}>
          <AuthenticationProvider>
            <MobileProvider>
              <MenuProvider locale={locale}>{component}</MenuProvider>
            </MobileProvider>
          </AuthenticationProvider>
        </WithLocale>
      );
    }

    case "/[locale]/purchase/[id]": {
      return (
        <WithLocale locale={locale}>
          <ApolloProvider client={apolloClient}>
            <AuthenticationProvider>
              <MobileProvider>
                <CalendarProvider>
                  <ToastContainer
                    style={{ zIndex: 9999999 }}
                    position="bottom-right"
                    autoClose={5000}
                    newestOnTop={true}
                    limit={1}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={true}
                    hideProgressBar={true}
                  />
                  {component}
                </CalendarProvider>
              </MobileProvider>
            </AuthenticationProvider>
          </ApolloProvider>
        </WithLocale>
      );
    }

    default: {
      return (
        <Page
          apolloClient={apolloClient}
          cartId={checkoutId}
          locale={locale}
          pathname={pathname}
        >
          {component}
          <Scripts environment={env.ENVIRONMENT} scripts={injectedScripts} />
        </Page>
      );
    }
  }
}

MyApp.getInitialProps = async ({
  Component,
  ctx,
}: ApolloAppContext): Promise<MyAppInitialProps> => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  const locale = getLocale(ctx);
  const { pathname } = ctx;

  return {
    pageProps,
    locale,
    pathname,
  };
};

export default MyApp;
