import {
  ApolloClient,
  ApolloClientOptions,
  HttpLink,
  InMemoryCache,
  InMemoryCacheConfig,
  NormalizedCacheObject,
} from "@apollo/client";
import merge from "deepmerge";
import fetch from "isomorphic-unfetch";
import { useMemo } from "react";

import { env } from "../../environment";
import possibleTypes from "../types/generated/possibleTypes.json";

const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const cacheConfig: InMemoryCacheConfig = {
  possibleTypes,
  typePolicies: {
    Cart: {
      fields: {
        attributes: {
          merge: false,
        },
      },
    },
    Checkout: {
      fields: {
        customAttributes: {
          merge: false,
        },
      },
    },
    Product: {
      fields: {
        options: {
          merge: false,
        },
        tags: {
          merge: false,
        },
      },
    },
    ProductVariant: {
      fields: {
        selectedOptions: {
          merge: false,
        },
      },
    },
  },
};

export function createApolloClient(
  locale: string,
  options?: Partial<ApolloClientOptions<NormalizedCacheObject>>,
): ApolloClient<NormalizedCacheObject> {
  const httpLink = new HttpLink({
    uri: env.SHOPIFY_GRAPHQL_URI, // Server URL (must be absolute)
    credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
    headers: {
      "Accept-Language": locale,
      "X-Shopify-Storefront-Access-Token": env.SHOPIFY_ACCESS_TOKEN,
    },
    fetch,
  });
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: httpLink,
    cache: new InMemoryCache(cacheConfig),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
    },
    ...options,
  });
}

export function initializeApollo(
  locale: string,
  initialState?: NormalizedCacheObject,
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient(locale);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  if (!apolloClient && typeof window !== "undefined") {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

interface PageProps {
  props: {
    __APOLLO_STATE__?: NormalizedCacheObject;
    [key: string]: unknown;
  };
  __APOLLO_STATE__?: NormalizedCacheObject;
  [key: string]: unknown;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: PageProps,
): PageProps {
  if (pageProps.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(
  pageProps: PageProps,
  locale: string,
): ApolloClient<NormalizedCacheObject> {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(locale, state), [locale, state]);
  return store;
}
