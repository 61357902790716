import { ApolloError, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { MutationCartLinesAddArgs } from "@shopify/hydrogen-react/storefront-api-types";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

import { CART_LINES_ADD } from "../graphql/cart";
import { Cart, CartLinesUpdatePayload } from "../types/shopify";

export type UseCartLinesAddData = {
  cartLinesAdd?: CartLinesUpdatePayload;
};
export type UseCartLinesAddVariables = MutationCartLinesAddArgs;

type UseCartLinesAdd = {
  data?: CartLinesUpdatePayload | null | undefined;
  error?: ApolloError;
  loading: boolean;
  addCartLines: (
    args: UseCartLinesAddVariables,
  ) => Promise<Cart | null | undefined>;
};

const useCartLinesAdd = (): UseCartLinesAdd => {
  const [mutation, { data, error: apolloError, loading }] = useMutation<
    UseCartLinesAddData,
    UseCartLinesAddVariables
  >(CART_LINES_ADD);

  if (apolloError) {
    Sentry.captureException(apolloError);
  }

  const addCartLines = useCallback(
    async ({
      cartId,
      lines,
    }: UseCartLinesAddVariables): Promise<Cart | null | undefined> => {
      if (cartId) {
        try {
          const { data, errors: mutationErrors } = await mutation({
            variables: { cartId, lines },
          });

          if (mutationErrors?.length) {
            mutationErrors.forEach(mutationError => {
              Sentry.captureException(mutationError);
            });

            return null;
          }

          if (data?.cartLinesAdd?.userErrors.length) {
            data.cartLinesAdd.userErrors.forEach(userError => {
              Sentry.captureException(userError);
            });

            return null;
          }

          return data?.cartLinesAdd?.cart;
        } catch (error) {
          toast.error(
            <p data-testid="toast">
              <FormattedMessage id="cart.errorOnUpdatingCart" />
            </p>,
          );

          if (
            error instanceof Error &&
            error.message?.includes("Too many requests")
          ) {
            Sentry.captureMessage("Checkout update: Too many requests", {
              level: "error",
            });
          } else {
            Sentry.captureException(error);
          }
          return null;
        }
      }
      return null;
    },
    [mutation],
  );

  return {
    data: data?.cartLinesAdd,
    error: apolloError,
    loading: typeof window === "undefined" ? false : loading,
    addCartLines,
  };
};

export default useCartLinesAdd;
