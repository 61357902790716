import { ApolloError, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import {
  MutationCartAttributesUpdateArgs,
  UserError,
} from "@shopify/hydrogen-react/storefront-api-types";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

import { CART_ATTRIBUTES_UPDATE } from "../graphql/cart";
import {
  AttributeInput,
  Cart,
  CartAttributesUpdatePayload,
} from "../types/shopify";

interface UseCartAttributes {
  updateAttributes: (
    cartId: Cart["id"],
    attributes: AttributeInput[],
  ) => Promise<Cart | null>;
  data?: CartAttributesUpdatePayload;
  error?: ApolloError;
  loading: boolean;
}

export interface UseCartAttributesData {
  cartAttributesUpdate: {
    cart: Cart;
    userErrors: UserError[];
  };
}

const useCartAttributes = (): UseCartAttributes => {
  const [mutation, { data, error: apolloError, loading }] = useMutation<
    CartAttributesUpdatePayload,
    MutationCartAttributesUpdateArgs
  >(CART_ATTRIBUTES_UPDATE);

  if (apolloError) {
    Sentry.captureException(apolloError);
  }

  const updateAttributes: UseCartAttributes["updateAttributes"] = useCallback(
    async (cartId, attributes) => {
      try {
        const { data, errors: mutationErrors } = await mutation({
          variables: { cartId, attributes },
        });

        if (mutationErrors?.length) {
          mutationErrors.forEach(mutationError => {
            Sentry.captureException(mutationError);
          });

          return null;
        }

        return data?.cart ?? null;
      } catch (error) {
        toast.error(
          <p data-testid="toast">
            <FormattedMessage id="cart.errorOnUpdatingCart" />
          </p>,
        );
        Sentry.captureException(error);
        return null;
      }
    },
    [mutation],
  );

  return {
    updateAttributes,
    loading: typeof window === "undefined" ? true : loading,
    error: apolloError,
    data: data ?? undefined,
  };
};

export default useCartAttributes;
